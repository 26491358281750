import logo1 from "../assets/img/Eco-logo-white-1.webp";
import logo2 from "../assets/img/logo-2.png";
// import "../style.css";
import "../line-awesome.min.css";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../assets/css/custom.css";

function Navgation() {
  const location = useLocation();
  return (
    <header className="header-style-01">
      <nav className="navbar navbar-area white-nav nav-absolute navbar-two navbar-expand-lg">
        <div
          className={`container custom-container-one nav-container ${
            location.pathname === "/HotelDetails" ||
            location.pathname === "/Checkout" ||
            location.pathname === "/KochiHotelDetails" ||
            location.pathname === "/SindhudurgHotelDetails"
              ? "bg-white"
              : ""
          }`}
        >
          <div className="logo-wrapper">
            <NavLink to="/" className="logo">
              <img
                src={
                  location.pathname === "/HotelDetails" ||
                  location.pathname === "/Checkout" ||
                  location.pathname === "/KochiHotelDetails" ||
                  location.pathname === "/SindhudurgHotelDetails"
                    ? logo2
                    : logo1
                }
                alt=""
              />
            </NavLink>
          </div>
          <div className="responsive-mobile-menu d-lg-none">
            <a href="javascript:void(0)" className="click-nav-right-icon">
              <i
                className={`las la-ellipsis-v  ${
                  location.pathname === "/HotelDetails"
                    ? "text-secondary"
                    : null
                }`}
              ></i>
            </a>
            <button
              className={`navbar-toggler ${
                location.pathname === "/HotelDetails" ||
                location.pathname === "/Checkout" ||
                location.pathname === "/KochiHotelDetails" ||
                location.pathname === "/SindhudurgHotelDetails"
                  ? "bg-secondary"
                  : null
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#hotel_booking_menu"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse " id="hotel_booking_menu">
            <ul className="navbar-nav">
              <li
                className={`${
                  location.pathname === "/HotelDetails" ||
                  location.pathname === "/Checkout" ||
                  location.pathname === "/KochiHotelDetails" ||
                  location.pathname === "/SindhudurgHotelDetails"
                    ? "custom-text"
                    : ""
                }`}
              >
                <NavLink to="/"> Home </NavLink>
              </li>
              <li
                className={`${
                  location.pathname === "/HotelDetails" ||
                  location.pathname === "/Checkout" ||
                  location.pathname === "/KochiHotelDetails" ||
                  location.pathname === "/SindhudurgHotelDetails"
                    ? "custom-text"
                    : ""
                }`}
              >
                <NavLink to="/hotel"> Hotels </NavLink>
              </li>
              <li
                className={`${
                  location.pathname === "/HotelDetails" ||
                  location.pathname === "/Checkout" ||
                  location.pathname === "/KochiHotelDetails" ||
                  location.pathname === "/SindhudurgHotelDetails"
                    ? "custom-text"
                    : ""
                }`}
              >
                <NavLink to="/Events"> Events </NavLink>
              </li>
              <li
                className={`${
                  location.pathname === "/HotelDetails" ||
                  location.pathname === "/Checkout" ||
                  location.pathname === "/KochiHotelDetails" ||
                  location.pathname === "/SindhudurgHotelDetails"
                    ? "custom-text"
                    : ""
                }`}
              >
                <NavLink to="/PromotionOffer"> Offer & Promotions </NavLink>
              </li>
              <li
                className={`${
                  location.pathname === "/HotelDetails" ||
                  location.pathname === "/Checkout" ||
                  location.pathname === "/KochiHotelDetails" ||
                  location.pathname === "/SindhudurgHotelDetails"
                    ? "custom-text"
                    : ""
                }`}
              >
                <NavLink to="/AboutUs"> About Us </NavLink>
              </li>
              <li
                className={`${
                  location.pathname === "/HotelDetails" ||
                  location.pathname === "/Checkout" ||
                  location.pathname === "/KochiHotelDetails" ||
                  location.pathname === "/SindhudurgHotelDetails"
                    ? "custom-text"
                    : ""
                }`}
              >
                <NavLink to="https://ehrlindia.in/ehrlindia.in/investor-relations.html">
                  {" "}
                  Investor Relations{" "}
                </NavLink>
              </li>
            </ul>
          </div>
          {/* <div className="navbar-right-content show-nav-content">
            <div className="single-right-content">
              <div className="navbar-right-flex">
                <div className="btn-wrapper">
                  <a href="#" className="cmn-btn btn-bg-1 radius-10">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </nav>
    </header>
  );
}

export default Navgation;
