import logo7 from "../assets/img/single-page/logo1.png";
import logo3 from "../assets/img/single-page/logo3.png";
import logo4 from "../assets/img/single-page/logo4.png";
import logo5 from "../assets/img/single-page/logo5.png";
import logo6 from "../assets/img/single-page/logo6.png";
import logo1 from "../assets/img/Eco-logo-white-1.webp";
import logo8 from "../assets/img/single-page/logo7.png";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { CheckCircleOutline } from "@mui/icons-material";
import Subscription from "./helper/Subscription";

function Footer() {
  var settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 2500,
    swipeToSlide: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const [email, setEmail] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSubmitEmail = async () => {
    try {
      const res = await axios.post(
        "https://ecohotelsresortslimited.od2.vtiger.com/restapi/vtap/webhook/newsletter_subcribtion",
        {
          email: email,
        },
        {
          headers: {
            Token: "YZEGZ3wVJuEgvyn4eN7rkK6I",
          },
        }
      );

      if (res) {
        setIsPopupOpen(true);
        setEmail("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isPopupOpen && (
        <Subscription title="Newsletter" onClose={() => setIsPopupOpen(false)}>
          <div style={{ textAlign: "center" }}>
            <CheckCircleOutline style={{ fontSize: "100px", color: "green" }} />
            <h5 style={{ fontSize: "24px", color: "green", margin: "40px" }}>
              SUCCESSFULLY SUBMITTED
            </h5>
          </div>
        </Subscription>
      )}
      <section className="partner-area pat-50 pab-0">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> Our Partners </h2>
            <div className="section-title-shapes"></div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <Slider
                {...settings1}
                className="global-slick-init attraction-slider slider-inner-margin"
                data-slidesToShow="6"
                data-infinite="true"
                data-arrows="false"
                data-dots="false"
                data-swipeToSlide="true"
                data-autoplay="true"
                data-autoplaySpeed="2500"
                data-prevArrow='<div className="prev-icon"><i className="las la-angle-left"></i></div>'
                data-nextArrow='<div className="next-icon"><i className="las la-angle-right"></i></div>'
                data-responsive='[{"breakpoint": 1400,"settings": {"slidesToShow": 5}},{"breakpoint": 1200,"settings": {"slidesToShow": 4}},{"breakpoint": 992,"settings": {"slidesToShow": 3}},{"breakpoint": 576, "settings": {"slidesToShow": 2} }]'
              >
                <div className="single-brand">
                  <a href="javascript:void(0)" className="single-brand-thumb">
                    <img src={logo8} alt="brandLogo" />
                  </a>
                </div>
                <div className="single-brand">
                  <a href="javascript:void(0)" className="single-brand-thumb">
                    <img src={logo7} alt="brandLogo" />
                  </a>
                </div>

                <div className="single-brand">
                  <a href="javascript:void(0)" className="single-brand-thumb">
                    <img src={logo4} alt="brandLogo" />
                  </a>
                </div>
                <div className="single-brand">
                  <a href="javascript:void(0)" className="single-brand-thumb">
                    <img src={logo8} alt="brandLogo" />
                  </a>
                </div>

                <div className="single-brand">
                  <a href="javascript:void(0)" className="single-brand-thumb">
                    <img src={logo6} alt="brandLogo" />
                  </a>
                </div>
                <div className="single-brand">
                  <a href="javascript:void(0)" className="single-brand-thumb">
                    <img src={logo5} alt="brandLogo" />
                  </a>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-area footer-area-two footer-bg-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-wrapper">
                <div className="footer-wrapper-flex">
                  <div className="footer-wrapper-single">
                    <div className="footer-widget widget">
                      <div className="footer-widget-contents">
                        <div className="footer-widget-logo">
                          <Link to="/">
                            {" "}
                            <img className="logo" src={logo1} alt="logo" />{" "}
                          </Link>
                        </div>
                        <div className="footer-widget-inner mt-4">
                          <p className="footer-widget-contents-para">
                            THE WORLD’S FIRST CARBON NEUTRAL HOTEL BRAND OF ITS
                            KIND
                          </p>
                          <div className="copyright-contents-items mt-5">
                            <div className="copyright-contents-single">
                              <div className="copyright-contents-single-flex">
                                <div className="copyright-contents-single-icon">
                                  <i className="las la-phone"></i>
                                </div>
                                <div className="copyright-contents-single-details">
                                  <span className="copyright-contents-single-details-title">
                                    {" "}
                                    Have a question?{" "}
                                  </span>
                                  <a
                                    href="tel:9650350602"
                                    className="copyright-contents-single-details-link"
                                  >
                                    +91 9650350602
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="copyright-contents-single">
                              <div className="copyright-contents-single-flex">
                                <div className="copyright-contents-single-icon">
                                  <i className="las la-envelope"></i>
                                </div>
                                <div className="copyright-contents-single-details">
                                  <span className="copyright-contents-single-details-title">
                                    {" "}
                                    Have a question?{" "}
                                  </span>
                                  <a
                                    href="mailto:revenuehead@ecohotels.in"
                                    className="copyright-contents-single-details-link"
                                  >
                                    {" "}
                                    revenuehead@ecohotels.in
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-wrapper-single">
                    <div className="footer-widget widget">
                      <h3 className="footer-widget-title text-white">
                        {" "}
                        Discounts, Newsletters{" "}
                      </h3>
                      <div className="footer-widget-inner mt-4">
                        <p className="footer-widget-para">
                          {" "}
                          Get discounts and newsletters on our hotels in your
                          email. We promise to not spam. Unsubscribe anytime{" "}
                        </p>
                        <div className="footer-widget-form mt-5">
                          <div>
                            <div className="footer-widget-form-single">
                              <input
                                className="footer-widget-form-control"
                                type="text"
                                placeholder="Enter Your Email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              <button onClick={handleSubmitEmail}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-navbar">
          <div className="container">
            <div className="footer-widget widget">
              <div className="footer-widget-nav">
                <ul className="footer-widget-nav-list list-style-none">
                  <li className="footer-widget-nav-list-item">
                    <Link to="/AboutUs" className="footer-widget-nav-list-link">
                      {" "}
                      About{" "}
                    </Link>
                  </li>
                  <li className="footer-widget-nav-list-item">
                    <Link
                      to="/UpcomingHotel"
                      className="footer-widget-nav-list-link"
                    >
                      {" "}
                      Upcoming Hotels{" "}
                    </Link>
                  </li>
                  <li className="footer-widget-nav-list-item">
                    <Link
                      to="/NewsList"
                      className="footer-widget-nav-list-link"
                    >
                      {" "}
                      News{" "}
                    </Link>
                  </li>

                  <li className="footer-widget-nav-list-item">
                    <a
                      href="javascript:void(0)"
                      className="footer-widget-nav-list-link"
                    >
                      {" "}
                      Careers{" "}
                    </a>
                  </li>
                  <li className="footer-widget-nav-list-item">
                    <a
                      href="https://ehrlindia.in/ehrlindia.in/investor-relations.html"
                      className="footer-widget-nav-list-link"
                    >
                      {" "}
                      Investor Relations{" "}
                    </a>
                  </li>
                  <li className="footer-widget-nav-list-item">
                    <a
                      href="javascript:void(0)"
                      className="footer-widget-nav-list-link"
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li className="footer-widget-nav-list-item">
                    <Link to="/Contact" className="footer-widget-nav-list-link">
                      {" "}
                      Contact{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area footer-padding copyright-bg-1">
          <div className="container">
            <div className="copyright-contents">
              <div className="copyright-contents-flex">
                <div className="footer-widget-social">
                  <ul className="footer-widget-social-list list-style-none justify-content-center">
                    <li className="footer-widget-social-list-item">
                      <a
                        className="footer-widget-social-list-link"
                        href="javascript:void(0)"
                      >
                        {" "}
                        <i className="lab la-facebook-f"></i>{" "}
                      </a>
                    </li>
                    <li className="footer-widget-social-list-item">
                      <a
                        className="footer-widget-social-list-link"
                        href="javascript:void(0)"
                      >
                        {" "}
                        <i className="lab la-twitter"></i>{" "}
                      </a>
                    </li>
                    <li className="footer-widget-social-list-item">
                      <a
                        className="footer-widget-social-list-link"
                        href="javascript:void(0)"
                      >
                        {" "}
                        <i className="lab la-instagram"></i>{" "}
                      </a>
                    </li>
                    <li className="footer-widget-social-list-item">
                      <a
                        className="footer-widget-social-list-link"
                        href="javascript:void(0)"
                      >
                        {" "}
                        <i className="lab la-youtube"></i>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <span className="copyright-contents-main">
                  {" "}
                  © 2024, alchimie Platform by{" "}
                  <a href="02_index.html">
                    {" "}
                    Katalyst Consulting Services{" "}
                  </a>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
