import React, { useEffect, useState } from "react";
import banner from "../../assets/img/hotel/banner.png";
import bannerMobile from "../../assets/img/hotel/banner-mobile.png";
import { Link } from "react-router-dom";
import SearchEngine from "./SearchEngine";
import axios from "../../lib/axiosConfig";

const HotelBanner = ({ image, image_mobile }) => {
  // -------------------------------- HANDLE HOTEL QUERY -------------------------------------//

  const [properties, setProperties] = useState([]);
  const [selection, setSelection] = useState({
    location: {
      propertyId: "",
      companyId: "",
    },
    checkIn: "",
    checkOut: "",
    person: 0,
    children: 0,
    room: 0,
  });

  function handleOnchangeSelection(key, value) {
    const updatedSelection = { ...selection };
    if (key === "person" && parseInt(value) > 2) {
      updatedSelection.room = 2;
    }
    updatedSelection[key] = value;
    setSelection(updatedSelection);
  }

  // -------------------------------- HANDLE HOTEL QUERY -------------------------------------//

  // -------------------------------- SEARCH HOTEL QUERY API -------------------------------------//
  const fetchProperties = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `/public/properties/booking-mode/Day`,
      });

      setProperties(res?.data?.data);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);
  // -------------------------------- SEARCH HOTEL QUERY API -------------------------------------//

  return (
    <>
      <div className="d-none d-xl-flex align-items-center justify-content-center position-relative">
        {/* Blurred Background */}
        <div
          className="eco-hotel-blur position-absolute"
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${image_mobile || bannerMobile })`, // The same image to be blurred
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(10px)",
            zIndex: -1, // Make sure it's behind the content
          }}
        ></div>

        {/* Content Container */}
        <div className="position-relative">
          <div className="eco-hotel-hero text-center">
            <div className="eco-hotel-image">
              <picture>
                <source
                  media="(max-width: 780px)"
                  srcSet={image_mobile || bannerMobile}
                />
                <source media="(max-width: 1023px)" srcSet={image || banner} />
                <img
                  src={image || banner}
                  alt="Hotel"
                  className="hotel-image img-fluid"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className=" py-4 text-start">
              <h2 className="fw-bold label">ECO HOTELS</h2>
              <p className="label fw-bold">ECONOMICAL AND ECOLOGICAL</p>
              <p className="label">
                The world’s first carbon-neutral
                <br />
                hotel brand of its kind.
              </p>
            </div>
          </div>
        </div>
        <div
          className="position-absolute w-100"
          style={{
            bottom: -60,
          }}
        >
          <SearchEngine
            onChangeSelection={handleOnchangeSelection}
            properties={properties}
            selection={selection}
          />
        </div>
      </div>

      <div className="eco-hotel-container d-xl-none">
        {/* Banner Section */}
        <div className="eco-hotel-hero">
          <div className="eco-hotel-image">
            <picture>
              <source
                media="(max-width: 780px)"
                srcSet={image_mobile || bannerMobile}
              />
              <source media="(max-width: 1023px)" srcSet={image || banner} />
              <img src={image || banner} alt="Hotel" className="hotel-image" />
            </picture>
          </div>
          <div className="eco-hotel-content">
            <h1 className="fw-bold">ECO HOTELS</h1>
            <p className="label fw-bold">ECONOMICAL AND ECOLOGICAL</p>
            <p className="label">
              The world’s first carbon-neutral hotel brand of its kind.
            </p>
          </div>
        </div>

        {/* Search Bar Section */}
        <SearchEngine
          onChangeSelection={handleOnchangeSelection}
          properties={properties}
          selection={selection}
        />
      </div>
    </>
  );
};

export default HotelBanner;
