import hotel1 from "../assets/img/single-page/hotel-grid1.jpg";
import { Link } from "react-router-dom";
import PriceFilter from "./helper/PriceFilter";

function DestinationLeft() {
  return (
    <>
      <div className="shop-sidebar-content">
        <div className="shop-close-content">
          <div className="shop-close-content-icon">
            <i className="las la-times"></i>
          </div>
          <div className="single-shop-left bg-white radius-10 mt-4">
            <div className="single-shop-left-title open">
              <h5 className="title"> Destination </h5>
              <div className="single-shop-left-inner margin-top-15">
                <ul className="single-shop-left-list active-list list-style-none">
                  {/* Search Input */}
                  <li className="item-search">
                    <input
                      type="text"
                      className="form--control"
                      placeholder="Search Destination"
                    />
                    <div className="item-search-icon">
                      <i className="las la-search"></i>
                    </div>
                  </li>

                  {/* List items with checkboxes */}
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Kota
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Kochi
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Sindhudurg
                    </label>
                  </li>
                  {/* <li className="item">
                    <label>
                      <input type="checkbox" /> Vadodara
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Lonavala
                    </label>
                  </li> */}

                  {/* More Destinations */}
                  {/* <li className="more-amenities">
                    <a href="javascript:void(0)">23 More Destination</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="single-shop-left bg-white radius-10 mt-4">
            <div className="single-shop-left-title open">
              <h5 className="title"> Amenities </h5>
              <div className="single-shop-left-inner margin-top-15">
                <ul className="single-shop-left-list active-list list-style-none pat-20">
                  {/* List items with checkboxes */}
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Free Parking
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Free Wifi
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Room Service
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Spa
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Fitness Centre
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Indoor Pool
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Outdoor Pool
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Meeting Rooms
                    </label>
                  </li>
                  <li className="item">
                    <label>
                      <input type="checkbox" /> Laundry
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <PriceFilter />
        </div>
      </div>
    </>
  );
}

export default DestinationLeft;
