import { Link } from "react-router-dom";
import HotelCard from "./Hotel/HotelCard";
import HotelCardSkeleton from "./skeleton/HotelCardSkeleton";
import HotelCardTwo from "./Hotel/HotelCardTwo";
import { useDispatch } from "react-redux";
import { setBookingData } from "../reducer/bookingHotelReducer";
import VadodaraThumbnail from "../assets/img/vadodara/thumbnail.png";
import SindhudurgThumbnail from "../assets/img/sindhudurg/thumbnail.png";
import LonavalaThumbnail from "../assets/img/lonavala/thumbnail.png";
import KochiThumbnail from "../assets/img/kochi/thumbnail.png";
import KotaThumbnail from "../assets/img/kota/thumbnail.png";
import Brand2 from "../assets/img/brand/brand2.png";
import Brand3 from "../assets/img/brand/brand3.png";
import Brand4 from "../assets/img/brand/brand4.png";

function DestinationRight({ reserveType, data, isLoading, page, url }) {
  const dispatch = useDispatch();

  function storeRoomDetails(index) {
    dispatch(setBookingData(data[index]));
  }

  return (
    <>
      <div className="shop-grid-contents">
        <div className="grid-list-contents">
          <div className="grid-list-contents-flex">
            <p className="grid-list-contents-para">
              Showing 3 - 1 of 3 results
            </p>
            <div className="grid-list-view">
              <ul className="grid-list-view-flex d-flex tabs list-style-none">
                <li className="grid-list-view-icons active" data-tab="tab-grid">
                  <a href="javascript:void(0)" className="icon">
                    {" "}
                    <i className="las la-border-all"></i>{" "}
                  </a>
                </li>
                <li className="grid-list-view-icons" data-tab="tab-list">
                  <a href="javascript:void(0)" className="icon">
                    {" "}
                    <i className="las la-bars"></i>{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="tab-grid" className="tab-content-item active mt-4">
          <div className="row gy-4">
            {page === "hotel-landing" ? (
              <>
                <div className="col-md-6">
                  <HotelCard
                    name={"The Eco Satva, Kota"}
                    caclPrice={"4999"}
                    address={"Kota"}
                    url={url}
                    EcoLogo={Brand3}
                    thumbnail={KotaThumbnail}
                  />
                </div>
                <div className="col-md-6">
                  <HotelCard
                    name={"The Eco Value, Kochi"}
                    caclPrice={"4999"}
                    address={"Kochi"}
                    url={"/KochiHotelDetails"}
                    thumbnail={KochiThumbnail}
                    EcoLogo={Brand2}
                  />
                </div>
                <div className="col-md-6">
                  <HotelCard
                    name={"The EcoXpress Sindhudurg"}
                    caclPrice={"4999"}
                    address={"Sindhudurg"}
                    url={"/SindhudurgHotelDetails"}
                    thumbnail={SindhudurgThumbnail}
                    EcoLogo={Brand4}
                  />
                </div>
                {/* <div className="col-md-6">
                  <HotelCard
                    name={"The Eco Vadodara"}
                    caclPrice={"1899"}
                    address={"Vadodara"}
                    url={"/VadodaraHotelDetails"}
                    thumbnail={VadodaraThumbnail}
                  />
                </div>
                <div className="col-md-6">
                  <HotelCard
                    name={"The Eco Satva Lonavala"}
                    caclPrice={"1899"}
                    address={"Lonavala"}
                    url={"/LonavalaHotelDetails"}
                    thumbnail={LonavalaThumbnail}
                  />
                </div> */}
              </>
            ) : isLoading ? (
              <HotelCardSkeleton />
            ) : (
              data?.map((item) => (
                <div key={item?._id} className="col-md-6">
                  <HotelCard
                    type={reserveType}
                    name={item?.name}
                    caclPrice={item?.caclPrice}
                    address={"address"}
                    url={url}
                  />
                </div>
              ))
            )}
          </div>
          <div className="row mt-5">
            <div className="col">
              <div className="pagination-wrapper">
                <ul className="pagination-list list-style-none">
                  <li className="pagination-list-item-prev">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-button"
                    >
                      {" "}
                      Prev{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item active">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      1{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      2{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      3{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      4{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      5{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item-next">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-button"
                    >
                      {" "}
                      Next{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="tab-list" className="tab-content-item mt-4">
          <div className="row gy-4">
            {data?.map((item, index) => (
              <div key={item?._id}>
                <HotelCardTwo
                  index={index}
                  type={reserveType}
                  name={item?.name}
                  caclPrice={item?.caclPrice}
                  address={"address"}
                  storeRoomDetails={storeRoomDetails}
                  url={url}
                />
              </div>
            ))}
          </div>
          <div className="row mt-5">
            <div className="col">
              <div className="pagination-wrapper">
                <ul className="pagination-list list-style-none">
                  <li className="pagination-list-item-prev">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-button"
                    >
                      {" "}
                      Prev{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item active">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      1{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      2{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      3{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      4{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      5{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item-next">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-button"
                    >
                      {" "}
                      Next{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DestinationRight;
