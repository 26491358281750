import "./style.css";
import "./line-awesome.min.css";
import NavBar from "./component/Navgation";
import Hompage from "./component/Home";
import CommomFooter from "./component/Footer";
import PromotionOffer from "./component/PromotionOffer";
import RewardsProgram from "./component/RewardProgram";
import Hotel from "./component/Hotel";
import HotelDetails from "./component/HotelDetails";
import AboutUs from "./component/AboutUs";
import Contact from "./component/Contact";
import UpcomingHotel from "./component/UpcomingHotel";
import Error from "./component/Error";
import OurBrand from "./component/OurBrand";
import Reservation from "./component/Reservation";
import NewsDetails from "./component/NewsDetails";
import ScrollRestoration from "./ScrollRestoration";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NewsList from "./component/NewsList";
import NearbyAttraction from "./component/Hotel/NearbyAttraction";
import HotelSearch from "./component/Hotel/HotelSearch";
import HotelBooking from "./component/Hotel/HotelBooking";
import CheckoutForm from "./component/Hotel/Checkout";
import KochiHotelDetails from "./component/KochiHotelDetails";
import SindhudurgHotelDetails from "./component/SindhudurgHotelDetails";
import VadodaraHotelDetails from "./component/VadodaraHotelDetails";
import LonavalaHotelDetails from "./component/LonavalaHotelDetails";
import SindhudurgNearbyAttraction from "./component/Hotel/NearbyAttractionPages/SindhudurgNearbyAttraction";
import KochiNearbyAttraction from "./component/Hotel/NearbyAttractionPages/KochiNearbyAttraction";
import Events from "./component/Event";

function App() {
  return (
    <>
      <Router>
        <ScrollRestoration />
        <NavBar />

        <Routes>
          <Route path="/" element={<Hompage />} />
          <Route path="/PromotionOffer" element={<PromotionOffer />} />
          <Route path="/RewardProgram" element={<RewardsProgram />} />
          <Route path="/Hotel" element={<Hotel />} />
          <Route path="/HotelDetails" element={<HotelDetails />} />
          <Route path="/KochiHotelDetails" element={<KochiHotelDetails />} />
          <Route
            path="/VadodaraHotelDetails"
            element={<VadodaraHotelDetails />}
          />
          <Route
            path="/LonavalaHotelDetails"
            element={<LonavalaHotelDetails />}
          />
          <Route
            path="/SindhudurgHotelDetails"
            element={<SindhudurgHotelDetails />}
          />
          <Route
            path="/SindhudurgNearbyAttraction"
            element={<SindhudurgNearbyAttraction />}
          />
          <Route
            path="/KochiNearbyAttraction"
            element={<KochiNearbyAttraction />}
          />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/UpcomingHotel" element={<UpcomingHotel />} />
          <Route path="/OurBrand" element={<OurBrand />} />
          <Route path="/Reservation" element={<Reservation />} />
          <Route path="/NewsDetails" element={<NewsDetails />} />
          <Route path="/NewsList" element={<NewsList />} />
          <Route path="/NearbyAttraction" element={<NearbyAttraction />} />
          <Route path="/HotelSearch" element={<HotelSearch />} />
          <Route path="/HotelBooking" element={<HotelBooking />} />
          <Route path="/Checkout" element={<CheckoutForm />} />
          <Route path="/temp" element={<Reservation />} />
          <Route path="/Events" element={<Events />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <CommomFooter />
      </Router>
    </>
  );
}

export default App;
