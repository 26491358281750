import hoteld1 from "../assets/img/kochi/banner1.jpeg";
import hoteld2 from "../assets/img/kochi/banner2.jpeg";
import hoteld3 from "../assets/img/kochi/banner3.jpeg";
import living from "../assets/img/hotel/bed-1.png";
import dining from "../assets/img/hotel/dining.jpeg";
import attraction from "../assets/img/hotel/attraction.png";
import summerOffer from "../assets/img/events/event-menu.png";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import HotelTab from "./Hotel/HotelTab";
import MapImage from "../assets/img/hotel/map.png";
import SearchBox from "./Hotel/SearchBox";
import ProfileCard from "./Hotel/ProfileCard";
import { Link } from "react-router-dom";
import EcoLogo from "../assets/img/single-page/EHRL-3.webp";
import nearby1 from "../assets/img/kochi/nearby1.png";
import nearby2 from "../assets/img/kochi/nearby2.png";
import nearby3 from "../assets/img/hotel/nearyby-3.png";
import { LivingContent } from "./content/EcoHotel";

const livingData = [
  {
    tab: "Overview",
    content:
      "At Eco Value Kochi, each of our 16 well-equipped rooms is designed to provide maximum comfort while maintaining a focus on sustainability. Whether you're visiting for a business trip, a family vacation, or a solo adventure, our rooms cater to all your needs.",
  },
  {
    tab: "Standard Double Room",
    content: LivingContent(
      "Our Standard Rooms offer the perfect blend of simplicity and modern comfort. Ideal for solo travelers or couples, these rooms provide all essential amenities in a compact and efficient layout.",
      4999
    ),
  },
];

const DiningData = (
  <>
    <p>
      Eco Value Kochi prioritizes both comfort and convenience when it comes to
      dining. While the hotel doesn’t have an in-house restaurant, we ensure
      guests have access to a range of dining options, both within the hotel and
      nearby, that reflect the local flavors and cater to diverse tastes.
    </p>
  </>
);

function KochiHotelDetails() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const divRefs = useRef([]);

  const sections = [
    {
      id: 1,
      label: "Living",
      image: living,
      type: "living",
      data: livingData,
    },
    {
      id: 2,
      label: "Dining",
      image: dining,
      type: "dining",
      data: [{ tab: "Overview", content: DiningData }],
    },
    { id: 4, label: "Nearby Attractions" },
  ];

  // Function to scroll to a specific div
  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const setting2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
    responsive: [
      {
        breakpoint: 1024, // Large devices, like laptops and desktops
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Medium devices, like tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Small devices, like smartphones
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviewSetting = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 1500,
    swipeToSlide: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",
  };
  return (
    <>
      {/* <TopBanner /> */}
      <section className="hotel-details-area section-bg-2 pat-120 pab-50">
        <div className="container">
          <SearchBox />

          <div className="row g-4 pat-20">
            <div className="col-xl-9 col-lg-7">
              <div className="details-left-wrapper">
                <div className="details-contents bg-white radius-10">
                  <div className="details-contents-header">
                    <div className="main">
                      <Slider
                        asNavFor={nav2}
                        ref={(slider) => (sliderRef1 = slider)}
                        arrows={false}
                        className="slider slide-show"
                      >
                        <div className="bg-image radius-5">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld1}
                            alt=""
                          />
                        </div>
                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld2}
                            alt=""
                          />
                        </div>
                        <div className="bg-image">
                          <img
                            className="img-fluid radius-5"
                            style={{ minWidth: "100%", minHeight: "100%" }}
                            src={hoteld3}
                            alt=""
                          />
                        </div>
                      </Slider>
                      <Slider
                        asNavFor={nav1}
                        ref={(slider) => (sliderRef2 = slider)}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={false}
                        {...settings}
                        className="global-slick-init attraction-slider nav-style-one nav-color-one"
                      >
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld1}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld2}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="custom-details-contents-header-thumb">
                          <img
                            src={hoteld3}
                            alt="img"
                            style={{
                              minWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>

                  <div className="hotel-view-contents">
                    <div className="hotel-view-contents-header">
                      <div className="d-flex align-items-center justify-content-between par-20">
                        <h3 className="fw-bold ">The Eco Value Kochi</h3>
                        <img src={EcoLogo} alt="Eco Logo" width={130} />
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-map-marker-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          Near High Court Kerala
                        </span>
                      </div>
                      <div className="hotel-view-contents-location mt-2">
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-phone-alt"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          +91 9650350602
                        </span>
                        <span className="hotel-view-contents-location-icon">
                          {" "}
                          <i className="las la-at"></i>{" "}
                        </span>
                        <span className="hotel-view-contents-location-para">
                          revenuehead@ecohotels.in
                        </span>
                      </div>
                    </div>
                    <div className="hotel-view-contents-middle">
                      <div className="hotel-view-contents-flex">
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-parking"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Parking{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-wifi"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Wifi{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-coffee"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Breakfast{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-quidditch"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Room Service{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-swimming-pool"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Pool{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-receipt"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Reception{" "}
                          </p>
                        </div>
                        <div className="hotel-view-contents-icon d-flex gap-1">
                          <span>
                            {" "}
                            <i className="las la-dumbbell"></i>{" "}
                          </span>
                          <p className="hotel-view-contents-icon-title flex-fill">
                            {" "}
                            Gym{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="hotel-view-contents-bottom">
                      <div className="pat-30 par-20">
                        <h5 className="fw-bold label">About</h5>
                        <p className="pat-5">
                          Eco Value Kochi is a budget-friendly hotel located in
                          a plush area near the High Court of Kerala, offering a
                          balance of comfort, convenience, and sustainability.
                          With 16 well-equipped rooms, the hotel provides modern
                          amenities like air conditioning, free Wi-Fi, satellite
                          TV, and tea/coffee makers, all designed to minimize
                          energy consumption while maintaining guest comfort.
                          <br />
                          <br />
                          Eco Value Kochi focuses on delivering high value at
                          affordable rates while keeping sustainability at the
                          core of its operations, making it a smart choice for
                          eco-conscious guests looking for an affordable stay in
                          the heart of Kochi.
                        </p>
                      </div>
                      {/* Top Navigation */}
                      <ul className="nav nav-pills my-4 gap-4 d-flex  justify-content-sm-center">
                        {sections?.map((item, index) => (
                          <li className="nav-item" key={item?.id}>
                            <div className="">
                              <span
                                className={`hotel-btn-wrapper hotel-btn ${
                                  index === 0 ? "active" : ""
                                }`}
                                onClick={() => scrollToDiv(index)}
                              >
                                {item?.label}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {sections
                        .map((section, index) => (
                          <div
                            key={section.id}
                            ref={(el) => (divRefs.current[index] = el)}
                          >
                            <HotelTab
                              title={section.label}
                              image={section.image}
                              type={section.type}
                              tab={true}
                              data={section.data}
                            />
                          </div>
                        ))
                        ?.slice(0, 2)}

                      {/* <HotelTab title="Dining" image={dining} type={"dining"} />
                      <HotelTab title="Events" image={attraction} /> */}
                      <section
                        className="attraction-area"
                        ref={(el) => (divRefs.current[3] = el)}
                      >
                        <div className="">
                          <div className="row my-5">
                            <h4
                              className="hotel-view-contents-bottom-title"
                              style={{
                                fontSize: "18px",
                              }}
                            >
                              Nearby Attractions
                            </h4>
                            <div className="col-12 mt-3">
                              <Slider
                                className="global-slick-init attraction-slider nav-style-one nav-color-two"
                                {...setting2}
                              >
                                <div>
                                  <Link to="/KochiNearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{ width: "90%" }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby1} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Fort Kochi
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Kochi
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/KochiNearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby2} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Mattancherry Palace
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Kochi
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div>
                                  <Link to="/KochiNearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby3} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Mangalavanam
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Kochi
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                                {/* 
                                <div>
                                  <Link to="/KochiNearbyAttraction">
                                    <div
                                      class="attraction-item"
                                      style={{
                                        width: " 90%",
                                        display: "inline-block;",
                                      }}
                                    >
                                      <div class="single-attraction">
                                        <div class="single-attraction-thumb">
                                          <img src={nearby4} alt="img" />
                                        </div>
                                        <div class="single-attraction-contents">
                                          <h4 class="single-attraction-contents-title">
                                            Jaisalmer
                                          </h4>
                                          <p class="single-attraction-contents-para">
                                            Rajasthan
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div> */}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-dropdown custom-form">
                      <form action="#">
                        <div className="single-input">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check In{" "}
                          </label>
                          <input
                            className="form--control date-picker flatpickr-input"
                            type="hidden"
                            placeholder="Check in"
                            value="06-08-2024 12:00"
                          />
                          <input
                            className="form--control date-picker form-control input"
                            placeholder="Check in"
                            tabindex="0"
                            type="text"
                            readonly="readonly"
                          />
                        </div>
                        <div className="single-input mt-3">
                          <label className="details-sidebar-dropdown-title">
                            {" "}
                            Check Out{" "}
                          </label>
                          <input
                            className="form--control date-picker flatpickr-input"
                            type="hidden"
                            placeholder="Check out"
                            value="07-08-2024 12:00"
                          />
                          <input
                            className="form--control date-picker form-control input"
                            placeholder="Check out"
                            tabindex="0"
                            type="text"
                            readonly="readonly"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="details-sidebar-quantity pt-4">
                      <div className="details-sidebar-quantity-flex">
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Guest{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="5"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Children{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="3"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                        <div className="details-sidebar-quantity-item">
                          <h6 className="details-sidebar-quantity-title">
                            {" "}
                            Room{" "}
                          </h6>
                          <div className="details-sidebar-quantity-field">
                            <span className="minus">
                              <i className="las la-minus"></i>
                            </span>
                            <input
                              className="quantity-input"
                              type="number"
                              value="2"
                            />
                            <span className="plus">
                              <i className="las la-plus"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper mt-4">
                      <Link
                        to="/Checkout"
                        className="cmn-btn btn-bg-1 btn-small"
                      >
                        {" "}
                        Reserve Now{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <p className="label">View in map</p>
                <img src={MapImage} alt="map" />

                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10 my-4">
                  <p className="label mb-2 fw-bold">
                    Celebrate Sustainably, Host Elegantly – Book Your
                    Eco-Friendly Event at The Eco Satva
                  </p>
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer-thumb">
                      <img src={summerOffer} alt="" />
                    </div>
                    <div className="btn-wrapper mt-3">
                      <Link to="/events" className="cmn-btn btn-bg-1 w-100">
                        {" "}
                        Book Now{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <div className="bg-white radius-20 p-4 pb-5">
                    <p className="label fw-bold">Review</p>
                    <Slider
                      arrows={false}
                      className="slider slide-show"
                      {...reviewSetting}
                    >
                      {[1, 2, 3, 4]?.map((i) => (
                        <ProfileCard key={i} />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default KochiHotelDetails;
