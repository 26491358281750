import bookicon1 from "../assets/img/icons/choice1.png";
import bookicon2 from "../assets/img/icons/choice2.png";
import bookicon3 from "../assets/img/icons/choice3.png";
import bookicon4 from "../assets/img/icons/choice4.png";
import destination1 from "../assets/img/Destination/Kochi.png";
import destination3 from "../assets/img/Destination/Kota.png";
import destination4 from "../assets/img/Destination/Sindhudurg.png";
import HappyHour from "../assets/img/single-page/happyhour.jpg";
import Staycation from "../assets/img/single-page/staycation.png";
import New1 from "../assets/img/Upcoming/Noida-imgg.jpg";
import Brand from "../assets/img/brand/brand.png";
import Brand1 from "../assets/img/brand/brand1.png";
import Brand2 from "../assets/img/brand/brand2.png";
import Brand3 from "../assets/img/brand/brand3.png";
import Brand4 from "../assets/img/brand/brand4.png";
import guestshade from "../assets/img/guest/guest-shape.png";
import guest1 from "../assets/img/guest/guest1.png";
import guest2 from "../assets/img/guest/guest2.png";
import guest3 from "../assets/img/guest/guest3.png";
import guest4 from "../assets/img/guest/guest4.png";
import guest5 from "../assets/img/guest/guest5.png";
import guest6 from "../assets/img/guest/guest6.png";
import shot1 from "../assets/img/shots/shot1.png";
import shot2 from "../assets/img/shots/shot2.png";
import shot3 from "../assets/img/shots/shot3.png";
import shot4 from "../assets/img/shots/shot4.png";
import shot5 from "../assets/img/shots/shot5.png";
import shot10 from "../assets/img/shots/shot10.png";
import shot11 from "../assets/img/shots/shot11.png";
import shot12 from "../assets/img/shots/shot12.png";
import Vadodara from "../assets/img/single-page/vadodra.png";
import TopBanner from "./Banner";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "../lib/axiosConfig";
import SearchEngine from "./Hotel/SearchEngine";
import { newsData } from "./UpcomingHotel";
import hoteld1 from "../assets/img/hotel/hotel.png";
import hoteld4 from "../assets/img/kota/room1.jpeg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <divasa className="next-icon radius-parcent-50" onClick={onClick}>
      <i className="las la-angle-right"></i>
    </divasa>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="prev-icon radius-parcent-50" onClick={onClick}>
      <i className="las la-angle-left"></i>
    </div>
  );
}

export const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 2500,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var settings1 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: "60px",
    customPaging: (i) => <div className="custom-dot"></div>,
    dotsClass: "custom-slick-dots custom-dots",

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // -------------------------------- HANDLE HOTEL QUERY -------------------------------------//

  const [properties, setProperties] = useState([]);
  const [selection, setSelection] = useState({
    location: {
      propertyId: "",
      companyId: "",
    },
    checkIn: "",
    checkOut: "",
    person: 0,
    children: 0,
    room: 0,
  });

  const [nearbyPageData] = useState([
    {
      name: "Jai Mahal, Rajasthan",
      image: shot1,
    },
    {
      name: "Sindhudurg Fort, Sindhudurg",
      image: shot2,
    },
    {
      name: "Baja Caves, Lonavala",
      image: shot3,
    },
    {
      name: "Lohagad Fort",
      image: shot4,
    },
    {
      name: "Cherai Beach, Kochi",
      image: shot5,
    },
    {
      name: "Fort Kochi, Kochi",
      image: shot10,
    },
    {
      name: "Champaner, Vadodara",
      image: shot11,
    },
    {
      name: "Udaipur, Rajasthan",
      image: shot12,
    },
  ]);

  const brands = [
    {
      name: "Eco Hotel",
      image: Brand1,
    },
    {
      name: "The Eco Satva",
      image: Brand2,
    },
    {
      name: "Eco Value",
      image: Brand3,
    },
    {
      name: "Eco Xpress (Satva)",
      image: Brand4,
    },
  ];

  function handleOnchangeSelection(key, value) {
    const updatedSelection = { ...selection };

    // Update the key with the new value
    updatedSelection[key] = value;

    // Parse the current values for person and child
    const numPeople = parseInt(updatedSelection.person) || 0;
    const numChildren = parseInt(updatedSelection.children) || 0;

    // Check if either people or children count exceeds 2
    if (numPeople > 2 || numChildren > 2) {
      updatedSelection.room = 2;
    } else {
      updatedSelection.room = 1;
    }

    // Update the selection state with the new values
    setSelection(updatedSelection);
  }

  // -------------------------------- HANDLE HOTEL QUERY -------------------------------------//

  // -------------------------------- SEARCH HOTEL QUERY API -------------------------------------//
  const fetchProperties = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `/public/properties/booking-mode/Day`,
        headers: {
          Authorization: "",
        },
      });

      setProperties(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);
  // -------------------------------- SEARCH HOTEL QUERY API -------------------------------------//

  return (
    <>
      <TopBanner />
      <div className="location-area">
        <SearchEngine
          onChangeSelection={handleOnchangeSelection}
          properties={properties}
          selection={selection}
        />
      </div>
      <section className="guest-area pat-100 pab-50">
        <div className="container">
          <div className="section-title-three append-flex">
            <h2 className="title"> Choose Your Destination </h2>
            <div className="append-attraction append-color-two"></div>
          </div>
          <div className="space-y-2 my-4 mt-5">
            <Slider {...settings1}>
              <div className="guest-two-item">
                <Link to="/HotelDetails">
                  <div className="single-guest-three single-guest-two-border radius-5">
                    <div className="single-guest-two-flex2">
                      <div className="">
                        <img
                          src={destination3}
                          alt="img"
                          width={150}
                          height={150}
                        />
                      </div>
                      <div className="single-guest-two-contents">
                        <div className="single-guest-two-contents-country">
                          <span className="single-guest-two-contents-country-name2">
                            Kota
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="guest-two-item">
                <Link to="/KochiHotelDetails">
                  <div className="single-guest-three single-guest-two-border radius-5">
                    <div className="single-guest-two-flex2">
                      <div className="">
                        <img
                          src={destination1}
                          alt="img"
                          width={150}
                          height={150}
                        />
                      </div>
                      <div className="single-guest-two-contents">
                        <div className="single-guest-two-contents-country">
                          <span className="single-guest-two-contents-country-name2">
                            Kochi
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="guest-two-item">
                <Link to="/SindhudurgHotelDetails">
                  <div className="single-guest-three single-guest-two-border radius-5">
                    <div className="single-guest-two-flex2">
                      <div className="">
                        <img
                          src={destination4}
                          alt="img"
                          width={150}
                          height={150}
                        />
                      </div>
                      <div className="single-guest-two-contents">
                        <div className="single-guest-two-contents-country">
                          <span className="single-guest-two-contents-country-name2">
                            Sindhudurg
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              {/* <div className="card">
                <Link to="/HotelDetails">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <img
                        src={destination3}
                        className="img-fluid"
                        style={{ height: "100%", objectFit: "cover" }}
                        alt="Kota"
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                      <div className="card-body text-center">
                        <h5 className="card-title" style={{ color: "#d4af37" }}>
                          Kota
                        </h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card">
                <Link to="/KochiHotelDetails">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <img
                        src={destination1}
                        className="img-fluid"
                        style={{ height: "100%", objectFit: "cover" }}
                        alt="Kochi"
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                      <div className="card-body text-center">
                        <h5 className="card-title" style={{ color: "#d4af37" }}>
                          Kochi
                        </h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="card">
                <Link to="/SindhudurgHotelDetails">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <img
                        src={destination4}
                        className="img-fluid"
                        style={{ height: "100%", objectFit: "cover" }}
                        alt="Sindhudurg"
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                      <div className="card-body text-center">
                        <h5 className="card-title" style={{ color: "#d4af37" }}>
                          Sindhudurg
                        </h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div> */}

              {/* <div className="col-xxl-4 col-lg-4 col-md-6 p-3">
                <Link to="/SindhudurgHotelDetails">
                  <div className="single-blog">
                    <div className="single-blog-thumbs">
                      <img
                        className="lazyloads w-100 h-50"
                        src={destination4}
                        alt="Kota"
                      />
                    </div>
                    <div className="single-blog-contents mt-3">
                      <h4 className="single-blog-contents-title mt-3">
                        Sindhudurg
                      </h4>
                    </div>
                  </div>
                </Link>
              </div> */}
            </Slider>
          </div>
        </div>
      </section>

      <section className="booking-area pat-50 pab-100">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> Why We’re Your Best Choice </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row gy-4 mt-5">
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single-why center-text bg-white single-why-border radius-5">
                <div className="single-why-icon mb-4">
                  <img src={bookicon1} alt="icon" />
                </div>
                <div className="single-why-contents mt-3">
                  <h4 className="single-why-contents-title">
                    <a href="javascript:void(0)">
                      Top-Tier Eco-Friendly Hotels
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single-why center-text bg-white single-why-border radius-5">
                <div className="single-why-icon mb-4">
                  <img src={bookicon2} alt="icon" />
                </div>
                <div className="single-why-contents mt-3">
                  <h4 className="single-why-contents-title">
                    <a href="javascript:void(0)"> Book with Confidence </a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single-why center-text bg-white single-why-border radius-5">
                <div className="single-why-icon mb-4">
                  <img src={bookicon3} alt="icon" />
                </div>
                <div className="single-why-contents mt-3">
                  <h4 className="single-why-contents-title">
                    <a href="javascript:void(0)">
                      Committed to Carbon Neutrality
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single-why center-text bg-white single-why-border radius-5">
                <div className="single-why-icon mb-4">
                  <img src={bookicon4} alt="icon" />
                </div>
                <div className="single-why-contents mt-3">
                  <h4 className="single-why-contents-title">
                    <a href="javascript:void(0)">
                      Sustainability at Every Step
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="shots-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> Nearby Attractions </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="imgLoaded mt-5 d-none d-sm-block">
            <div className="row g-4 masonry-grid">
              <div className="col-lg-2 col-md-4 col-sm-6 grid-item">
                <div
                  className="single-shots bg-image"
                  style={{ backgroundImage: "url(" + shot1 + ")" }}
                >
                  <div className="single-shots-contents">
                    <h4 className="single-shots-contents-title">
                      <a href="hotel_details.html">Jai Mahal, Rajasthan</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 grid-item">
                <div
                  className="single-shots bg-image"
                  style={{ backgroundImage: "url(" + shot2 + ")" }}
                >
                  <div className="single-shots-contents">
                    <h4 className="single-shots-contents-title">
                      <a href="hotel_details.html">
                        Sindhudurg Fort, Sindhudurg
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 grid-item">
                <div
                  className="single-shots bg-image"
                  style={{ backgroundImage: "url(" + shot3 + ")" }}
                >
                  <div className="single-shots-contents">
                    <h4 className="single-shots-contents-title">
                      <a href="hotel_details.html">Baja Caves, Lonavala</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 grid-item">
                <div
                  className="single-shots bg-image"
                  style={{ backgroundImage: "url(" + shot4 + ")" }}
                >
                  <div className="single-shots-contents">
                    <h4 className="single-shots-contents-title">
                      <a href="hotel_details.html"> Lohagad Fort </a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 grid-item">
                <div
                  className="single-shots bg-image"
                  style={{ backgroundImage: "url(" + shot5 + ")" }}
                >
                  <div className="single-shots-contents">
                    <h4 className="single-shots-contents-title">
                      <a href="hotel_details.html">Cherai Beach, Kochi</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 grid-item">
                <div
                  className="single-shots bg-image"
                  style={{ backgroundImage: "url(" + shot10 + ")" }}
                >
                  <div className="single-shots-contents">
                    <h4 className="single-shots-contents-title">
                      <a href="hotel_details.html"> Fort Kochi, Kochi </a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 grid-item">
                <div
                  className="single-shots bg-image"
                  style={{ backgroundImage: "url(" + shot11 + ")" }}
                >
                  <div className="single-shots-contents">
                    <h4 className="single-shots-contents-title">
                      <a href="hotel_details.html">Champaner, Vadodara</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 grid-item">
                <div
                  className="single-shots bg-image"
                  style={{ backgroundImage: "url(" + shot12 + ")" }}
                >
                  <div className="single-shots-contents">
                    <h4 className="single-shots-contents-title">
                      <a href="hotel_details.html"> Udaipur, Rajasthan </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 d-sm-none p-2">
            <Slider
              className="global-slick-init attraction-slider nav-style-one nav-color-two"
              {...settings}
            >
              {nearbyPageData?.map((item) => (
                <div>
                  <Link to="/NearbyAttraction">
                    <div className="attraction-item">
                      <div className="single-attraction">
                        <div className="nearby-attraction-thumb">
                          <img
                            src={item?.image}
                            alt={item?.name}
                            className="image-cover"
                          />
                        </div>
                        <div className="single-attraction-contents">
                          <h4 className="single-attraction-contents-title">
                            {item?.name}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      <section className="question-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title">Upcoming Hotels </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row g-4 mt-5">
            <div className="col-xl-12 col-lg-12">
              <img src={Vadodara} alt="img" className="d-lg-none p-2" />
              <div className="single-guest-one radius-5">
                <div className="single-guest-two-flex gap-3">
                  <div className=" col-xl-6 col-lg-6">
                    <img
                      src={Vadodara}
                      alt="img"
                      className="d-none d-lg-block"
                    />
                  </div>
                  <div className="content-container col-xl-6 col-lg-6 single-guest-two-contents position-relative">
                    <div className="single-guest-two-contents-country-name">
                      <div className="d-flex align-items-center justify-content-between mt-4 mt-lg-0 ">
                        <h4 className="label fw-bold">Eco Vadodara</h4>
                        <img
                          src={Brand1}
                          alt="Vadodata"
                          width={70}
                          height={70}
                          className="position-absolute-right-top"
                        />
                      </div>
                      <span className="single-guest-two-contents-country-name mt-4">
                        Eco Hotels and Resorts, which focuses on eco-friendly
                        options and net-zero carbon emissions, on Monday
                        announced the opening of 57-key ’The Eco’ in Vadodara,
                        Gujarat. The new hotel is part of the hospitality
                        company's plans to have a 500-room capacity by the end
                        of December 2024, the firm said in a statement. “We are
                        dedicated to promoting eco-conscious travel options
                        across India, illustrated by our latest property, ’The
                        Eco’ in Vadodara takes us closer to over 500 rooms by
                        the end of December 2024 and a vision to operate 5,000
                        keys in India over the next five years and scale up to
                        15,000 rooms within the next 10 years
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="guest-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> What our guests think </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="custom-tab guest-wrapper mt-5">
            <div className="guest-wrapper-shapes">
              <img src={guestshade} alt="" />
            </div>
            <div className="custom-tab-menu">
              <ul className="tab-menu guest-wrapper-images list-style-none">
                <li className="guest-wrapper-images-single active">
                  <img src={guest1} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest2} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest3} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest4} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest5} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest6} alt="" />
                </li>
              </ul>
            </div>
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-lg-6 col-md-8">
                <div className="tab-area">
                  <div className="single-guest tab-item active center-text">
                    <div className="single-guest-thumb">
                      <img src={guest1} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        Comfort and Sustainability Combined Staying at this eco
                        hotel was a fantastic experience. The rooms were
                        incredibly comfortable. It’s clear that this hotel is
                        committed to reducing its carbon footprint, and that
                        made my stay even more enjoyable.
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Amit Patel
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest2} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        "Perfect Balance of Luxury and Eco-Friendliness” This
                        hotel exceeded my expectations. The luxurious amenities
                        didn’t compromise its commitment to sustainability.
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Meera Kapoor
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest3} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        ”A Sustainable Stay Done Right” I was blown away by the
                        level of eco-consciousness at this hotel. They’ve really
                        thought of everything—from water conservation efforts to
                        waste reduction programs.
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Rajesh Kumar
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest4} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        “Sustainability Done Right” This hotel sets the bar high
                        for eco-friendly hospitality. From the moment I arrived,
                        I noticed the attention to detail in their sustainable
                        practices. The energy-efficient appliances were great.
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Sanjay Rao
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest5} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        “Green Luxury at Its Best” I’ve stayed at many hotels,
                        but this one truly stands out for its commitment to the
                        environment. Highly recommend this place if you want
                        luxury with a conscience.”
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Vikram
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest6} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        “Eco-Conscious and Cozy” Staying at this eco hotel was a
                        refreshing change. The atmosphere was cozy and inviting,
                        with an emphasis on sustainability that I really
                        appreciated. It’s the perfect place for anyone looking
                        to travel responsibly.”
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Aishwarya Desai
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="attraction-area section-bg-2 pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title">Offers & Promotions</h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row gy-0 gx-0 mt-5">
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <a href="#" className="gallery-popup-two">
                    <img src={Staycation} alt="img" />
                  </a>
                </div>
                <div className="single-attraction-two-contents">
                  <h4 className="single-attraction-two-contents-title">
                    <Link to="/PromotionOffer">
                      Explore Staycation Packages
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <a href="#" className="gallery-popup-two">
                    <img src={HappyHour} alt="img" />
                  </a>
                </div>
                <div className="single-attraction-two-contents">
                  <h4 className="single-attraction-two-contents-title">
                    <Link to="/PromotionOffer"> </Link> Enjioy the Discounts on
                    our Happy Hour offer
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="question-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title">Our Vision </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row g-4 mt-5">
            <div className="col-xl-12 col-lg-12">
              <div className="single-guest-one single-guest-two-borderbox radius-5">
                <div className="single-guest-two-flex">
                  <div className="col-xl-6 col-lg-6 single-guest-two-contents">
                    <h4 className="single-guest-two-contents-title mt-4">
                      <span className="color-one">
                        THE WORLD’S FIRST CARBON NEUTRAL
                      </span>
                      <br></br>HOTEL BRAND OF ITS KIND
                    </h4>
                    <div className="single-guest-two-contents-country-name">
                      <span className="single-guest-two-contents-country-name mt-4">
                        Eco Hotels’ objective is to become a leading owner,
                        developer and asset manager of three star premium and
                        economy brands in the BRICS and N11 economies, with
                        India being the first target market, where the
                        opportunity for growth is the greatest.
                      </span>
                      <span className="single-guest-two-contents-country-name mt-4">
                        Eco Hotels has completed the development of the two
                        brands with one prototype Ecolodge operating in Kochi,
                        Kerala, India and intends to increase its operating
                        inventory in excess of 15,000 rooms over 10 years by way
                        of acquiring existing properties, refurbishing them to
                        achieve carbon neutrality and also by rolling out new
                        build portfolio using offsite modular building
                        technology.
                      </span>
                      <div className="btn-wrapper mt-4">
                        <Link
                          to="/AboutUs"
                          className="cmn-btn btn-bg-1 radius-10"
                        >
                          Click here for more details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <a href="javascript:void(0)">
                      <img src={New1} alt="img" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="history-area pat-50 pab-50 custom-bg-color">
        <div className="container">
          <div className="section-title-three text-left append-flex">
            <h2 className="title"> Our Brands </h2>
            <div className="append-history"></div>
          </div>
          <div className="d-none d-sm-block">
            <div className="row g-4 mt-5 ">
              <div className="col-xl-3 col-sm-6 col-md-6 col-lg-6">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand1} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-md-6 col-lg-6">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand2} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-md-6 col-lg-6">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand3} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-md-6 col-lg-6">
                <div className="sticky-top">
                  <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                    <div className="details-sidebar">
                      <div className="details-sidebar-offer-thumb text-center">
                        <img src={Brand4} alt="" height={160} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3 d-sm-none">
            <Slider
              className="global-slick-init attraction-slider nav-style-one nav-color-two"
              {...settings}
            >
              {brands?.map((item) => (
                <div className="guest-two-item">
                  <div className="single-guest-three single-guest-two-border radius-5">
                    <div className="single-guest-two-flex2">
                      <div className="">
                        <img
                          src={item.image}
                          alt="img"
                          width={150}
                          height={150}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className="blog-area pat-70 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> Latest News </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row gy-4 mt-5">
            <Slider {...settings1}>
              {newsData?.map((item) => (
                <div className="col-xxl-4 col-lg-4 col-md-6 p-3">
                  <div className="single-blog">
                    <div className="single-blog-thumbs">
                      <img
                        className="lazyloads"
                        src={item.image || hoteld4}
                        alt=""
                        height={220}
                      />

                      <div className="single-blog-thumbs-date">
                        <Link to="javascript:void(0)">
                          <span className="date"> {item.date} </span>{" "}
                          <span className="month"> {item.month} </span>{" "}
                        </Link>
                      </div>
                    </div>
                    <div className="single-blog-contents mt-3">
                      <h4 className="single-blog-contents-title2 mt-3">
                        {" "}
                        <Link to={item.link}>{item.title}</Link>
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
