import React, { Fragment } from "react";
import room1 from "../../assets/img/hotel/room1.png";
import { Link } from "react-router-dom";
const RoomCard = ({ data }) => {
  return (
    <>
      <div className="mb-4 ">
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={data?.images[0]}
              className="img-fluid rounded-start"
              alt="King Suite"
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="label fw-bold">DLX SINGL</h5>
              <p className="card-text pat-10">
                Indulge in the epitome of luxury and sustainability with our
                King Suite Room at The Eco Satva Kota. Designed for those who
                appreciate space, comfort, and eco-conscious living, the King
                Suite offers a perfect blend of opulence and responsibility.
              </p>
              <p className="card-text pat-10">
                <small className="label">Size - 185 sq.m</small>
              </p>

              <div className="pat-10">
                <div className="hotel-view-contents-flex">
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-parking"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-wifi"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-coffee"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-quidditch"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-swimming-pool"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-receipt"></i>{" "}
                    </span>
                  </div>
                  <div className="hotel-view-contents-icon d-flex gap-1">
                    <span>
                      {" "}
                      <i className="las la-dumbbell"></i>{" "}
                    </span>
                  </div>
                </div>
              </div>

              <div className="d-flex mb-3">
                <span className="me-3">
                  <i className="bi bi-wifi"></i>
                </span>
                <span className="me-3">
                  <i className="bi bi-tv"></i>
                </span>
                <span className="me-3">
                  <i className="bi bi-air-conditioner"></i>
                </span>
              </div>
            </div>
          </div>

          {data?.addOns?.map((item, index) => (
            <Fragment key={index}>
              <hr />
              <div className="d-flex align-items-center justify-content-between py-3">
                <div className="col-md-6 mb-2">
                  <div className="">
                    <p>Room ({item?.groupName})</p>
                    {item?.items?.map((d, i) => (
                      <Fragment key={i}>
                        <p className="label pat-5">{d?.name} - ₹ {d?.price}/Night</p>
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="btn-wrapper">
                  <Link to="/checkout" className="cmn-btn btn-bg-1 btn-small">
                    Book Now
                  </Link>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default RoomCard;
