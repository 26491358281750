import { Link } from "react-router-dom";
export const LivingContent = (content, price) => {
  return (
    <>
      <p>{content}</p>

      <div className="pat-10">
        <div className="hotel-view-contents-flex">
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-parking"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-wifi"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-coffee"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-quidditch"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-swimming-pool"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-receipt"></i>{" "}
            </span>
          </div>
          <div className="hotel-view-contents-icon d-flex gap-1">
            <span>
              {" "}
              <i className="las la-dumbbell"></i>{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div>
          <h6 className="label">{price}/Night</h6>
        </div>
        <div className="btn-wrapper par-20">
          <Link to="/Checkout">
            <button className="cmn-btn btn-bg-1 btn-small">Book Now</button>
          </Link>
        </div>
      </div>
    </>
  );
};
export const diningContent = (
  <>
    <p>
      The Main Restaurant offers an elegant dining experience, combining gourmet
      cuisine with eco-friendly principles. This indoor fine dining space is
      designed for guests seeking a more formal yet comfortable environment.
      With a focus on both local and international flavors, the restaurant
      caters to a diverse palate, while prioritizing sustainability in every
      aspect of the dining process.
    </p>
    <h6 className="label mt-2">Timings</h6>
    <div className="row align-items-center px-md-3 mt-2">
      <div className="col-2 col-md-2">
        <p className="label">Breakfast:</p>
        <p className="label">Lunch:</p>
        <p className="label">Dinner:</p>
      </div>
      <div className="col-6 col-md-6 ">
        <p className="text-gold">7:00 AM – 10:30 AM</p>
        <p className="text-gold">12:00 PM – 3:00 PM</p>
        <p className="text-gold">7:00 PM – 11:00 PM</p>
      </div>
      <div className="col-12 col-md-3 d-flex justify-content-end btn-wrapper par-20 mt-3 mt-md-0 order-last order-md-0">
        <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link>
      </div>
    </div>
  </>
);

export const restroBar = (
  <>
    <p>
      With a seating capacity of 100, the restobar is designed to offer a
      breathtaking panoramic view of the city while delivering a relaxed,
      upscale dining atmosphere. Whether for casual drinks, a romantic evening,
      or a social gathering, the rooftop restobar is the ideal setting for
      memorable moments.
    </p>
    <br />
    <div className="d-flex justify-content-between align-items-end">
      <div>
        <h6 className="label">Timings</h6>
        <p className="label mt-2">
          Everyday: <span className="text-gold">5:00 PM – 12:00 AM</span>
        </p>
      </div>
      <div className="btn-wrapper par-20">
        <Link to="/Checkout">
          <button className="cmn-btn btn-bg-1 btn-small">Reserve Now</button>
        </Link>
      </div>
    </div>
  </>
);

export const EventBanquetOne = ({ setPopupOpen }) => {
  return (
    <>
      <h6 className="label">The Grand Pavilion</h6>
      <p>Capacity: Up to 200 guests</p>
      <p>Price: ₹60,000 per day </p>
      <p>Features & Amenities:</p>
      <div className="d-flex justify-content-between align-items-end">
        <ul>
          <li>Elegant design</li>
          <li>Customizable Layouts</li>
          <li>Sustainable Decor Options</li>
          <li>Climate Control</li>
        </ul>
        <div className="btn-wrapper par-20">
          <button onClick={setPopupOpen} className="cmn-btn btn-bg-1 btn-small">
            Reserve Now
          </button>
        </div>
      </div>
    </>
  );
};
export const EventBanquetTwo = ({ setPopupOpen }) => (
  <>
    <h6 className="label">The Harmony Suite</h6>
    <p>Capacity: Up to 200 guests</p>
    <p>Price: ₹40,000 per day </p>
    <p>Features & Amenities:</p>
    <div className="d-flex justify-content-between align-items-end">
      <ul>
        <li>Intimate Setting</li>
        <li>Built-in Audio-Visual System</li>
        <li>Eco-Friendly Lighting</li>
        <li>Sustainable Catering Options</li>
      </ul>
      <div className="btn-wrapper par-20">
        <button onClick={setPopupOpen} className="cmn-btn btn-bg-1 btn-small">
          Reserve Now
        </button>
      </div>
    </div>
  </>
);
