import Brand1 from "../assets/img/single-page/EHRL-1.webp";
import Brand2 from "../assets/img/single-page/EHRL-2.webp";
import Brand3 from "../assets/img/single-page/EHRL-3.webp";
import Brand4 from "../assets/img/single-page/EHRL-4.webp";
import TopBanner from "./Banner";

function OurBrand() {
  return (
    <>
      <TopBanner />
      <section className="about-area pat-100 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="fw-bold color-one">
              {" "}
              FOUR PROMISING BRANDS FROM ECO HOTELS
            </h2>
            <div className="section-title-shapes"> </div>
            <p className="section-para">
              {" "}
              Eco Hotels is tailored to meet the needs and expectations of the
              conscious traveller, both business and leisure. It consists of
              four sub-brands: The Eco, Eco Xpress, Eco Value and Eco Satva.{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="hotel-details-area section-bg-2 pat-100 pab-100">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-3 col-md-6 col-lg-6">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer-thumb">
                      <img src={Brand1} alt="" />
                    </div>
                    <div className="hotel-view-contents-header">
                      <h3 className="hotel-view-contents-title color-one">
                        {" "}
                        ECO Hotel{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-lg-6">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer-thumb">
                      <img src={Brand2} alt="" />
                    </div>
                    <div className="hotel-view-contents-header">
                      <h3 className="hotel-view-contents-title color-one color-one">
                        {" "}
                        ECO Hotel{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-lg-6">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer-thumb">
                      <img src={Brand3} alt="" />
                    </div>
                    <div className="hotel-view-contents-header">
                      <h3 className="hotel-view-contents-title color-one">
                        {" "}
                        ECO Hotel{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-lg-6">
              <div className="sticky-top">
                <div className="hotel-details-widget hotel-details-widget-padding widget bg-white radius-10">
                  <div className="details-sidebar">
                    <div className="details-sidebar-offer-thumb">
                      <img src={Brand4} alt="" />
                    </div>
                    <div className="hotel-view-contents-header">
                      <h3 className="hotel-view-contents-title color-one">
                        {" "}
                        ECO Hotel{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area pat-100 pab-50">
        <div className="container">
          <div className="section-title text-left">
            <h2 className="color-one pb-5">
              {" "}
              Holding and Ultimate Holding Companies
            </h2>
            <p className="single-para"> Eco Hotels UK Plc: </p>
            <p className="pb-5 mt-1 faq-para">
              Holding company name is Eco Hotels UK Plc which is based in UK.
              Holding Company’s objective is to become a leading owner,
              developer and asset manager of three star premium and economy
              brands in the BRICS and N11 economies, with India being the first
              target market, where the opportunity for growth is the greatest.{" "}
            </p>
          </div>
          <div className="section-title text-left">
            <p className="single-para"> Red Ribbon AMC: </p>
            <p className="pb-5 mt-1 faq-para">
              RRAMC is the ultimate holding company of Eco Hotels India group
              and direct holding company of Eco Hotels UK Plc. RRAMC is also
              situated in UK.
            </p>
          </div>
          <div className="section-title text-left">
            <p className="single-para"> Our Subsidiaries: </p>
            <p className="pb-5 mt-1 faq-para">
              Eco Hotel and Resort Limited has a subsidiary named Eco Hotels
              India Private Limited in which the latter holds more that 99%
              stake. Both the companies operate on an asset light model.
            </p>
          </div>
          <div className="section-title text-left">
            <p className="single-para"> Associate Companies: </p>
            <p className="mt-1 faq-para">
              Modulex Group – Modulex Construction Technologies Limited is
              listed on BSE is affiliated to Modulex Moduler Buildings PLC .
              Modulex is a pioneer in construction technology, with a steadfast
              commitment to carbon neutrality and innovation, combined with a
              determination to harness the power of emerging technologies to
              meet critical housing and infrastructure needs across the globe,
              at pace, and with optimal efficiency.
            </p>
            <p className="faq-para">
              Modulex is a leading ConstrucTech Company, harnessing emerging
              technologies like Artificial Intelligence, Blockchain & Internet
              of Things (IoT) to meet the burgeoning housing and infrastructure
              needs at a pace and with optimal cost efficiency.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurBrand;
