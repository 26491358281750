import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSearchData } from "../../reducer/searchHotelReducer";

const SearchEngine = ({ properties, onChangeSelection, selection }) => {
  const dispatch = useDispatch();
  function handleSearch() {
    dispatch(setSearchData(selection));
  }
  return (
    <div className="container">
      <div className="banner-location bg-white radius-5">
        <div className="banner-location-flex">
          <div className="banner-location-single">
            <div className="banner-location-single-flex">
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  Location
                </span>
                <div className="banner-location-single-contents-dropdown">
                  <div className="select-container">
                    <select
                      className="js-select-2"
                      name="state"
                      onChange={(e) => {
                        const selectedValue = JSON.parse(e.target.value);
                        onChangeSelection("location", selectedValue);
                      }}
                    >
                      <option value={null}>Select Location </option>
                      {/* {properties?.map((item) => (
                        <option
                          key={item?._id}
                          value={JSON.stringify({
                            propertyId: item?._id,
                            companyId: item?.companyId,
                          })}
                        >
                          {item?.name}
                        </option>
                      ))} */}
                      <option>The Eco Satva, Kota</option>
                      <option>The EcoXpress Sindhudurg</option>
                      <option>The Eco Value, Kochi</option>
                    </select>
                    <i className="las la-map-marker-alt select-icon text-black fw-bold"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-location-single">
            <div className="banner-location-single-flex">
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  Check In
                </span>
                <div className="banner-location-single-contents-dropdown custom-select">
                  <input
                    id="startDate"
                    class="form-control p-3 label"
                    type="date"
                    onChange={(e) =>
                      onChangeSelection("checkIn", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="banner-location-single">
            <div className="banner-location-single-flex">
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  Check In
                </span>
                <div className="banner-location-single-contents-dropdown custom-select">
                  <input
                    id="startDate"
                    class="form-control p-3 label"
                    type="date"
                    onChange={(e) =>
                      onChangeSelection("checkOut", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="banner-location-single">
            <div className="banner-location-single-flex">
              {/* <div className="banner-location-single-icon">
                    <i className="las la-user-friends"></i>
                  </div> */}
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  {" "}
                  Person{" "}
                </span>
                <div className="d-flex">
                  <div className="banner-location-single-contents-dropdown custom-select">
                    <select
                      className="js-select-2"
                      name="state"
                      value={selection?.person}
                      onChange={(e) =>
                        onChangeSelection("person", e.target.value)
                      }
                    >
                      <option value={0}>Adult</option>
                      <option value={1}>1 Adult</option>
                      <option value={2}>2 Adults</option>
                      <option value={3}>3 Adults</option>
                      <option value={5}>4 Adults</option>
                    </select>
                  </div>
                  <div className="banner-location-single-contents-dropdown custom-select">
                    <select
                      className="js-select-2"
                      name="state"
                      value={selection?.children}
                      onChange={(e) =>
                        onChangeSelection("children", e.target.value)
                      }
                    >
                      <option value={0}>Children</option>
                      <option value={1}>1 Child</option>
                      <option value={2}>2 Children</option>
                      <option value={3}>3 Children</option>
                      <option value={4}>4 Children</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="banner-location-single-flex">
              <div className="banner-location-single-contents">
                <span className="banner-location-single-contents-subtitle">
                  Room
                </span>
                <div className="banner-location-single-contents-dropdown custom-select">
                  <select
                    className="js-select-2"
                    name="state"
                    value={selection?.room}
                    onChange={(e) =>
                      onChangeSelection("children", e.target.value)
                    }
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-location-single-search">
            <div className="search-suggestions-wrapper">
              <Link
                onClick={handleSearch}
                to={`/HotelSearch?property=${selection?.location?.propertyId}&company=${selection?.location?.companyId}&checkin=${selection?.checkIn}&checkout=${selection?.checkOut}&person=${selection?.person}&children=${selection?.children}`}
              >
                <div className="search-click-icon">
                  <i className="las la-search"></i>
                </div>
              </Link>
            </div>
            <div className="search-suggestion-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchEngine;
