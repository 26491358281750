import banner1 from "../assets/img/banner/banner-travel.png";

const Banner = () => {
  return (
    <div className="banner-area banner-area-two banner-bg">
      <div className="container">
        <div className="banner-area-flex">
          <div className="banner-single-content text-white">
            <h2 className="banner-single-content-title fw-700">
              <span className="banner-single-content-title-shape">
                {" "}
                Stay Green,{" "}
              </span>{" "}
              Travel{" "}
              <span className="banner-single-content-title-shape star-shape">
                {" "}
                Better{" "}
              </span>{" "}
            </h2>
            <p className="banner-single-content-para mt-4">
              {" "}
              Discover Eco-Friendly Hotels for Your Next Adventure Book
              sustainable stays that care for the planet and your comfort.
            </p>
          </div>
          <div className="banner-thumb-wrap">
            <div className="banner-area-thumb">
              <img src={banner1} alt="" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
