import React, { useEffect, useRef, useState } from "react";
import SearchBox from "../SearchBox";
import Slider from "react-slick";
import hoteld1 from "../../../assets/img/kochi/nearby/img1.png";
import hoteld2 from "../../../assets/img/kochi/nearby/img2.png";
import hoteld3 from "../../../assets/img/kochi/nearby/img3.png";
import food1 from "../../../assets/img/kochi/nearby/food1.png";
import food2 from "../../../assets/img/kochi/nearby/food2.png";
import food3 from "../../../assets/img/kochi/nearby/food3.png";
import food4 from "../../../assets/img/kochi/nearby/food4.png";
import culture from "../../../assets/img/kochi/nearby/culture.png";
import fort from "../../../assets/img/kochi/nearby/fort.png";
import travel from "../../../assets/img/kochi/nearby/travel.png";
import kochiPalace from "../../../assets/img/kochi/nearby/palace.png";
import Sanctuary from "../../../assets/img/kochi/nearby/sacn.png";
import Lake from "../../../assets/img/kochi/nearby/lake.png";
import sunnySvg from "../../../assets/img/hotel/sunny.svg";
import rainySvg from "../../../assets/img/hotel/rainy.svg";
import snowySvg from "../../../assets/img/hotel/weather_snowy.png";
import weather from "../../../assets/img/kota/nearby/weather.png";
import HotelTab from "../HotelTab";

const cultureContent = (
  <>
    <p>
      Kochi's culture is a fascinating blend of various traditions and
      influences, reflecting its long history as a trade hub. The city has a
      rich heritage, with influences from the Portuguese, Dutch, and British
      colonial periods, as well as its ancient maritime trade connections with
      the Middle East and China. The culture is evident in its art forms,
      cuisine, and festivals.
    </p>
    <br />
    <p>
      The population of Kochi is estimated to be around 700,000 within the city
      limits, with the larger metropolitan area housing over 2.1 million people.
      The city is cosmopolitan, with people from various parts of India and the
      world, contributing to its diverse demographic makeup.
    </p>
  </>
);

const travelContent = (
  <>
    <ul className="d-flex flex-column gap-4">
      <li>
        <span className="label">By Air:</span>Cochin International Airport
        (COK), located about 28 km from the city center, is the nearest airport
        and is well-connected to major cities in India and abroad.
      </li>
      <li>
        <span className="label">By Train:</span>Kochi has two main railway
        stations—Ernakulam Junction and Ernakulam Town—which are well-connected
        to major Indian cities.
      </li>
      <li>
        <span className="label">By Road:</span>Kochi is connected by a network
        of national and state highways. The KSRTC (Kerala State Road Transport
        Corporation) and private buses operate frequent services to nearby
        cities like Trivandrum, Bangalore, and Coimbatore.
      </li>
    </ul>
  </>
);

const KochiNearbyAttraction = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const divRefs = useRef([]);

  const sections = [
    {
      id: 2,
      label: "Cuture",
      image: culture,
      type: "dining",
      data: [{ content: cultureContent }],
    },
    {
      id: 3,
      label: "Travel",
      image: travel,
      type: "event",
      data: [{ content: travelContent }],
    },
    { id: 4, label: "Food" },
  ];

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const scrollToDiv = (index) => {
    if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <divasa className="next-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-right"></i>
      </divasa>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev-icon radius-parcent-50" onClick={onClick}>
        <i className="las la-angle-left"></i>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autodelayspeed: 5000,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <section className="hotel-details-area section-bg-2 pat-120 pab-50">
      <div className="container">
        <SearchBox />
        <div className="details-contents bg-white radius-10 mt-4">
          <div className="details-contents-header">
            <div className="main">
              <Slider
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
                arrows={false}
                className="slider slide-show"
              >
                <div className="bg-image radius-5">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld1}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld2}
                    alt=""
                  />
                </div>
                <div className="bg-image">
                  <img
                    className="img-fluid radius-5"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    src={hoteld3}
                    alt=""
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav1}
                ref={(slider) => (sliderRef2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                {...settings}
                className="global-slick-init attraction-slider nav-style-one nav-color-one"
              >
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld1}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld2}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="custom-details-contents-header-thumb">
                  <img
                    src={hoteld3}
                    alt="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Slider>
            </div>

            <div className="hotel-view-contents">
              <div className="hotel-view-contents-header">
                <h3 className="hotel-view-contents-title fw-bold">
                  Explore the Vibrant Kochi,Kerala
                </h3>
                <div className="mt-2">
                  <span className="fw-bold text-secondary">
                    Best Time to Visit Kochi
                  </span>
                  <div className="mt-1 d-flex align-items-center gap-3 label">
                    <img src={weather} alt="sunny" />{" "}
                    <span>October - February</span>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center gap-5">
                  <div>
                    <img src={sunnySvg} alt="sunny" />{" "}
                    <span>Apr - Jun (35°C to 45°C) </span>
                  </div>
                  <div>
                    <img src={rainySvg} alt="sunny" />{" "}
                    <span>Jul - Sep (25°C to 35°C) </span>
                  </div>
                  <div>
                    <img src={snowySvg} alt="sunny" />{" "}
                    <span>Oct - Mar (10°C to 25°C) </span>
                  </div>
                </div>
                <hr />
              </div>

              <div className="hotel-view-contents-bottom">
                <div className="pat-30 par-20">
                  <h5 className="fw-bold label">About</h5>
                  <p className="pat-5">
                    Kochi, also known as Cochin, is a vibrant port city located
                    on the southwest coast of India in Kerala. It is a blend of
                    modernity and tradition, known for its rich cultural
                    history, colonial architecture, and natural beauty. Often
                    referred to as the "Queen of the Arabian Sea," Kochi has
                    been a key trading port since ancient times, attracting
                    traders from Arabia, China, and Europe. The city is a
                    melting pot of cultures and communities, including Hindus,
                    Muslims, Christians, and Jews, which has given rise to a
                    unique and diverse cultural landscape.
                  </p>
                </div>
                {/* Top Navigation */}
                <ul className="nav nav-pills my-4 gap-4 d-flex justify-content-center">
                  {sections?.map((item, index) => (
                    <li className="nav-item" key={item?.id}>
                      <div className="">
                        <span
                          className={`hotel-btn-wrapper hotel-btn ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => scrollToDiv(index)}
                        >
                          {item?.label}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                <div>
                  {sections
                    .map((section, index) => (
                      <div
                        key={section.id}
                        ref={(el) => (divRefs.current[index] = el)}
                      >
                        <HotelTab
                          title={section.label}
                          image={section.image}
                          type={section.type}
                          data={section.data}
                        />
                      </div>
                    ))
                    ?.slice(0, 2)}
                  <div ref={(el) => (divRefs.current[2] = el)}>
                    <h4
                      className="hotel-view-contents-bottom-title"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Food in Kochi
                    </h4>
                    <p>
                      Kochi offers a wide variety of delicious cuisines,
                      reflecting its multicultural influences. Kerala cuisine,
                      with its liberal use of coconut, curry leaves, and spices,
                      is the highlight, but the city also offers international
                      cuisines due to its cosmopolitan population.:
                    </p>

                    <div className="pat-10">
                      <div className="row g-4">
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food1}
                                    alt="Appam with Stew"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Appam with Stew</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food2}
                                    alt="Meen Moilee"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">Meen Moilee</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food3}
                                    alt="Parotta with Beef Fry"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">
                                    Parotta with Beef Fry
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-lg-6">
                          <div className="sticky-top">
                            <div className="hotel-details-widget widget bg-white radius-10">
                              <div className="details-sidebar">
                                <div className="details-sidebar-offer-thumb">
                                  <img
                                    src={food4}
                                    alt="puttu"
                                    className="img-fixed-size"
                                  />
                                </div>
                                <div className="hotel-view-contents-header">
                                  <h6 className="label">
                                    Puttu and Kadala Curry
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pat-40">
                      <h4
                        className="hotel-view-contents-bottom-title"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Top Attractions Near The Eco Value Kochi
                      </h4>

                      <div className="pat-40">
                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img src={fort} className="radius-10" alt="Room" />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Fort Kochi</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Known for its colonial architecture, Chinese
                                    fishing nets, and historical landmarks, Fort
                                    Kochi is a major cultural hotspot. The St.
                                    Francis Church, Mattancherry Palace, and Jew
                                    Town are some highlights.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 8 km</p>
                                  <p>Travel Time: 15 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div
                              className="hide-scrollbar"
                              style={{
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "3rem",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Mattancherry Palace</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    It is a historic palace in Kochi, known for
                                    its traditional Kerala architecture,
                                    stunning murals, and collection of royal
                                    artifacts. Originally built by the
                                    Portuguese in 1555 and renovated by the
                                    Dutch in 1663, the palace showcases a blend
                                    of local and colonial influences. The murals
                                    depicting scenes from the Ramayana and
                                    Mahabharata are a highlight.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 8km</p>
                                  <p>Travel Time: 25 minutes by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={kochiPalace}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-md-4 d-flex justify-content-center">
                            <img
                              src={Sanctuary}
                              className="radius-10"
                              alt="Room"
                            />
                          </div>
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">
                              Mangalavanam Bird Sanctuary
                            </h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    Nestled in the heart of Kochi, the
                                    Mangalavanam Bird Sanctuary is a green lung
                                    space and a haven for birdwatchers. The
                                    sanctuary is home to various migratory and
                                    resident birds, making it an excellent spot
                                    for nature lovers who want a break from the
                                    urban environment.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 1 km</p>
                                  <p>Travel Time: 5 minutes by car</p>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-md-8 d-flex flex-column justify-content-between pt-4">
                            {/* Content Area */}
                            <h6 className="label ">Sursagar Lake</h6>
                            <div>
                              <div>
                                <div>
                                  <p>
                                    St. Francis Church in Kochi is one of the
                                    oldest European churches in India, built in
                                    1503 by Portuguese traders. It holds
                                    historical significance as the original
                                    burial site of the famous Portuguese
                                    explorer Vasco da Gama, who was later
                                    reinterred in Lisbon, Portugal. The church
                                    is an excellent example of early colonial
                                    architecture with a simple yet elegant
                                    design reflecting European influences.
                                  </p>
                                  <br />
                                  <p>Distance from Hotel: 8 km</p>
                                  <p>Travel Time: 25 minutes by car</p>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div className="col-md-4 d-flex justify-content-center">
                            <img src={Lake} className="radius-10" alt="Room" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KochiNearbyAttraction;
